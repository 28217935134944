import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12bb0ebd = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _21236146 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _2e2a655c = () => interopDefault(import('../pages/experts/index.vue' /* webpackChunkName: "pages/experts/index" */))
const _0d246162 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _7a02a778 = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _37be50f2 = () => interopDefault(import('../pages/rating.vue' /* webpackChunkName: "pages/rating" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2177e75a = () => interopDefault(import('../pages/spaces/index.vue' /* webpackChunkName: "pages/spaces/index" */))
const _bd245ebc = () => interopDefault(import('../pages/third-test.vue' /* webpackChunkName: "pages/third-test" */))
const _29f8de97 = () => interopDefault(import('../pages/user/index.js' /* webpackChunkName: "pages/user/index" */))
const _7364f08e = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _bddf5d3a = () => interopDefault(import('../pages/user/events.vue' /* webpackChunkName: "pages/user/events" */))
const _7a574fde = () => interopDefault(import('../pages/user/form/index.vue' /* webpackChunkName: "pages/user/form/index" */))
const _965279dc = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _015ebe0e = () => interopDefault(import('../pages/user/requests.vue' /* webpackChunkName: "pages/user/requests" */))
const _867e00c2 = () => interopDefault(import('../pages/user/form/new.vue' /* webpackChunkName: "pages/user/form/new" */))
const _f050940c = () => interopDefault(import('../pages/user/profile/edit.vue' /* webpackChunkName: "pages/user/profile/edit" */))
const _63290452 = () => interopDefault(import('../pages/user/rating/regions.vue' /* webpackChunkName: "pages/user/rating/regions" */))
const _35d25ada = () => interopDefault(import('../pages/user/rating/russia.vue' /* webpackChunkName: "pages/user/rating/russia" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _ade9aa8e = () => interopDefault(import('../pages/user/form/_id.vue' /* webpackChunkName: "pages/user/form/_id" */))
const _7e84fd2e = () => interopDefault(import('../pages/events/_id.vue' /* webpackChunkName: "pages/events/_id" */))
const _11254d81 = () => interopDefault(import('../pages/experts/_alias.vue' /* webpackChunkName: "pages/experts/_alias" */))
const _4c12efac = () => interopDefault(import('../pages/offers/_id.vue' /* webpackChunkName: "pages/offers/_id" */))
const _56c73a40 = () => interopDefault(import('../pages/partners/_id.vue' /* webpackChunkName: "pages/partners/_id" */))
const _6cabf842 = () => interopDefault(import('../pages/spaces/_id.vue' /* webpackChunkName: "pages/spaces/_id" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contacts",
    component: _12bb0ebd,
    name: "contacts___ru___default"
  }, {
    path: "/events",
    component: _21236146,
    name: "events___ru___default"
  }, {
    path: "/experts",
    component: _2e2a655c,
    name: "experts___ru___default"
  }, {
    path: "/map",
    component: _0d246162,
    name: "map___ru___default"
  }, {
    path: "/offers",
    component: _7a02a778,
    name: "offers___ru___default"
  }, {
    path: "/partners",
    component: _1c6abc50,
    name: "partners___ru___default"
  }, {
    path: "/rating",
    component: _37be50f2,
    name: "rating___ru___default"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/spaces",
    component: _2177e75a,
    name: "spaces___ru___default"
  }, {
    path: "/third-test",
    component: _bd245ebc,
    name: "third-test___ru___default"
  }, {
    path: "/user",
    component: _29f8de97,
    name: "user___ru___default"
  }, {
    path: "/users",
    component: _7364f08e,
    name: "users___ru___default"
  }, {
    path: "/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru___default"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru___default"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru___default"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru___default"
  }, {
    path: "/ru/contacts",
    component: _12bb0ebd,
    name: "contacts___ru"
  }, {
    path: "/ru/events",
    component: _21236146,
    name: "events___ru"
  }, {
    path: "/ru/experts",
    component: _2e2a655c,
    name: "experts___ru"
  }, {
    path: "/ru/map",
    component: _0d246162,
    name: "map___ru"
  }, {
    path: "/ru/offers",
    component: _7a02a778,
    name: "offers___ru"
  }, {
    path: "/ru/partners",
    component: _1c6abc50,
    name: "partners___ru"
  }, {
    path: "/ru/rating",
    component: _37be50f2,
    name: "rating___ru"
  }, {
    path: "/ru/spaces",
    component: _2177e75a,
    name: "spaces___ru"
  }, {
    path: "/ru/third-test",
    component: _bd245ebc,
    name: "third-test___ru"
  }, {
    path: "/ru/user",
    component: _29f8de97,
    name: "user___ru"
  }, {
    path: "/ru/users",
    component: _7364f08e,
    name: "users___ru"
  }, {
    path: "/user/events",
    component: _bddf5d3a,
    name: "user-events___ru___default"
  }, {
    path: "/user/form",
    component: _7a574fde,
    name: "user-form___ru___default"
  }, {
    path: "/user/profile",
    component: _965279dc,
    name: "user-profile___ru___default"
  }, {
    path: "/user/requests",
    component: _015ebe0e,
    name: "user-requests___ru___default"
  }, {
    path: "/ru/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru"
  }, {
    path: "/ru/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/ru/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru"
  }, {
    path: "/ru/user/events",
    component: _bddf5d3a,
    name: "user-events___ru"
  }, {
    path: "/ru/user/form",
    component: _7a574fde,
    name: "user-form___ru"
  }, {
    path: "/ru/user/profile",
    component: _965279dc,
    name: "user-profile___ru"
  }, {
    path: "/ru/user/requests",
    component: _015ebe0e,
    name: "user-requests___ru"
  }, {
    path: "/user/form/new",
    component: _867e00c2,
    name: "user-form-new___ru___default"
  }, {
    path: "/user/profile/edit",
    component: _f050940c,
    name: "user-profile-edit___ru___default"
  }, {
    path: "/user/rating/regions",
    component: _63290452,
    name: "user-rating-regions___ru___default"
  }, {
    path: "/user/rating/russia",
    component: _35d25ada,
    name: "user-rating-russia___ru___default"
  }, {
    path: "/ru/user/form/new",
    component: _867e00c2,
    name: "user-form-new___ru"
  }, {
    path: "/ru/user/profile/edit",
    component: _f050940c,
    name: "user-profile-edit___ru"
  }, {
    path: "/ru/user/rating/regions",
    component: _63290452,
    name: "user-rating-regions___ru"
  }, {
    path: "/ru/user/rating/russia",
    component: _35d25ada,
    name: "user-rating-russia___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru___default"
  }, {
    path: "/ru/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/ru/user/form/:id",
    component: _ade9aa8e,
    name: "user-form-id___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru___default"
  }, {
    path: "/ru/events/:id",
    component: _7e84fd2e,
    name: "events-id___ru"
  }, {
    path: "/ru/experts/:alias",
    component: _11254d81,
    name: "experts-alias___ru"
  }, {
    path: "/ru/offers/:id",
    component: _4c12efac,
    name: "offers-id___ru"
  }, {
    path: "/ru/partners/:id",
    component: _56c73a40,
    name: "partners-id___ru"
  }, {
    path: "/ru/spaces/:id",
    component: _6cabf842,
    name: "spaces-id___ru"
  }, {
    path: "/user/form/:id",
    component: _ade9aa8e,
    name: "user-form-id___ru___default"
  }, {
    path: "/events/:id",
    component: _7e84fd2e,
    name: "events-id___ru___default"
  }, {
    path: "/experts/:alias",
    component: _11254d81,
    name: "experts-alias___ru___default"
  }, {
    path: "/offers/:id",
    component: _4c12efac,
    name: "offers-id___ru___default"
  }, {
    path: "/partners/:id",
    component: _56c73a40,
    name: "partners-id___ru___default"
  }, {
    path: "/spaces/:id",
    component: _6cabf842,
    name: "spaces-id___ru___default"
  }, {
    path: "/ru/*",
    component: _2efaf336,
    name: "all___ru"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___ru___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
