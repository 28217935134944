import { wrap } from "../_"

export default (a: NuxtAxiosInstance) => ({
  getHighlights: (cfg?: AxiosConfig) => wrap(
    a.$get("/highlight", {
      ...cfg,
    }),
  ),

  getHighlight: (highlightId: string) => wrap(
    a.$get(`/highlight/${highlightId}`),
  ),

  getRegions: () => wrap(
    a.$get("/highlight/region"),
  ),

  getCities: (region?: string) => wrap(
    a.$get("/highlight/city", {
      params: {
        "filter[region]": region,
      },
    }),
  ),
})
